const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const emailInput = document.querySelector("input#email");
const companyInput = document.querySelector("input#company");

const dehighlightValid = (el) => {
	el.style['border-color'] = '';
}
const highlightInvalid = (el) => {
	el.style['border-color'] = 'red';
}

const validateEmail = () => {
	const email = emailInput.value;
	if (!email || !email.match(emailRegex)) {
		return false
	}
	return true
}

const validateCompany = () => {
	const company = companyInput.value;
	if (!company || !company.trim()) {
		return false
	}
	return true;
}

const validateInput = () => {
	const companyValid = validateCompany();
	if (!companyValid) {
		highlightInvalid(companyInput);
	} else {
		dehighlightValid(companyInput);
	}

	const emailValid = validateEmail();
	if (!emailValid) {
		highlightInvalid(emailInput);
	} else {
		dehighlightValid(emailInput);
	}

	if (!companyValid || !emailValid) {
		return false
	}
	return true;
}

const submitForm = async () => {
	const valid = validateInput();
	if (!valid) {
		return
	}
	const b = { email: emailInput.value, company: companyInput.value };
	const res = await fetch('https://form-capture.samsmith453.workers.dev', {
		method: 'POST',
		body: JSON.stringify(b),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (res.status === 200) {
		// TODO: refer to blog
		location.assign('/submitted');
	}
}

window.submitForm = submitForm;
